
// import React from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import HeroPic1 from "../Images/HeroPic1.jpeg";
// import HeroPic2 from "../Images/HeroPic2.jpeg";
// import HeroPic3 from "../Images/HeroPic3.jpeg";
// import brandfoot from "../Images/brandfoot2.png"
// import "../CSS/Hero.css";
// import b1 from "../Images/b1 (1).png"
// import b2 from "../Images/b1 (2).png"
// import b3 from "../Images/b1 (3).png"
// import b4 from "../Images/b1 (4).png"

// const OurBrandProducts = () => {
//   return (
//     <div>
//       <div className='Hero'>
//       <Carousel
//         showArrows={true}
//         infiniteLoop={true}
//         showThumbs={false}
//         showStatus={false}
//         autoPlay={true}
//         interval={2000}
//       >
//         {/* <div>
//           <img src={HeroPic1} alt="HeroPic1" />
//         </div>
//         <div>
//           <img src={HeroPic2} alt="HeroPic2" />
//         </div> */}
//         <div>
//           <img src={HeroPic3} alt="HeroPic3" />
//         </div>
//       </Carousel>
      
//     </div>
//     <div className="carousel-container">
//     <h1>OUR BRAND</h1>
//     <div className="carousel">
//      <img src={b1} alt="b1"/>
//      <img src={b2} alt="b1"/>
//      <img src={b3} alt="b1"/>
//      <img src={b4} alt="b1"/>
//     </div>
    
//     <p><strong>Product Description:</strong> Happy Man Bitters is a premium herbal liqueur produced in Ghana by Charger Limited. Our bitters are crafted with the finest quality ingredients, including Khaya Senegalensis, Mondia White, Nauclea Ethano, and water, ensuring a unique and refreshing taste. With an alcohol content of 40%, our product promises not only quality but also an invigorating experience. Suitable for adults aged 18 and above, Happy Man Bitters is a perfect blend of tradition and excellence. Be a Happy Man and indulge in the finest herbal liqueur that brings joy and satisfaction.</p>
//     <button onClick={()=>window.history.back()}>Back </button>
//   </div>
//   <Carousel
//       showArrows={true}
//       infiniteLoop={true}
//       showThumbs={false}
//       showStatus={false}
//       autoPlay={true}
//       interval={2000}
//     >
//       {/* <div>
//         <img src={HeroPic1} alt="HeroPic1" />
//       </div>
//       <div>
//         <img src={HeroPic2} alt="HeroPic2" />
//       </div> */}
//       <div>
//         <img src={brandfoot} alt="HeroPic3" />
//       </div>
//     </Carousel>
//     </div>
//   );
// }

// export default OurBrandProducts;

import React, { useEffect, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeroPic3 from "../Images/HeroPic3.jpeg";
import brandfoot from "../Images/brandfoot2.png";
import "../CSS/Hero.css";
import b1 from "../Images/b1 (1).png";
import b2 from "../Images/b1 (2).png";
import b3 from "../Images/b1 (3).png";
import b4 from "../Images/b1 (4).png";
import "animate.css"; // Import animate.css

const OurBrandProducts = () => {
  const observer = useRef(null);

  useEffect(() => {
    // Create an IntersectionObserver to trigger animations when elements come into view
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const target = entry.target;

            // Apply animation classes to img, h1, and p elements
            if (target.tagName === 'IMG') {
              target.classList.add('animate__animated', 'animate__slideInUp');
            } else if (target.tagName === 'H1') {
              target.classList.add('animate__animated', 'animate__slideInLeft', 'animate__slower');
            } else if (target.tagName === 'P') {
              target.classList.add('animate__animated', 'animate__slideInUp', 'animate__slower');
            }

            // Unobserve element once animation is applied
            observer.current.unobserve(target);
          }
        });
      },
      { threshold: 0.3 } // Trigger when 30% of the element is visible
    );

    // Observe all img, h1, and p elements
    const imgElements = document.querySelectorAll('img');
    const h1Elements = document.querySelectorAll('h1');
    const pElements = document.querySelectorAll('p');

    imgElements.forEach((el) => observer.current.observe(el));
    h1Elements.forEach((el) => observer.current.observe(el));
    pElements.forEach((el) => observer.current.observe(el));

    // Cleanup observer on component unmount
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <div>
      <div className='Hero'>
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={2000}
        >
          <div>
            <img src={HeroPic3} alt="HeroPic3" />
          </div>
        </Carousel>
      </div>

      <div className="carousel-container">
        <h1>OUR BRAND</h1>
        <div className="carousel">
          <img src={b1} alt="b1" />
          <img src={b2} alt="b2" />
          <img src={b3} alt="b3" />
          <img src={b4} alt="b4" />
        </div>

        <p>
          <strong>Product Description:</strong> Happy Man Bitters is a premium herbal liqueur produced in Ghana by Charger Limited. Our bitters are crafted with the finest quality ingredients, including Khaya Senegalensis, Mondia White, Nauclea Ethano, and water, ensuring a unique and refreshing taste. With an alcohol content of 40%, our product promises not only quality but also an invigorating experience. Suitable for adults aged 18 and above, Happy Man Bitters is a perfect blend of tradition and excellence. Be a Happy Man and indulge in the finest herbal liqueur that brings joy and satisfaction.
        </p>
        <button onClick={() => window.history.back()}>Back</button>
      </div>

      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={2000}
      >
        <div>
          <img src={brandfoot} alt="HeroPic3" />
        </div>
      </Carousel>
    </div>
  );
};

export default OurBrandProducts;

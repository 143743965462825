import React from 'react'
import Header from "./components/Header"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LandingPage from './components/LandingPage'
import Footer from './components/Footer'
import BlogDetail from './components/BlogDetail'
import ScrollToTop from "./components/ScrollToTop"
import AboutUs from './components/AboutUs'
import ContactForm from './components/ContactUs'
import OurBrandProducts from './components/OurBrandProducts'
import OurStore from './components/OurStore'
import Career from './components/Career'
import wp from "./Images/whatsapplogo.png"
import LandingPageAbout from './components/LandingPageAbout'
import "./App.css"
import ChristmasOverlay from './components/ChristmassWelcomeMessage'
import { useState,useEffect } from 'react'
import ChristmassImg from "./Images/ChristmassImg.png"
import FooterBanner from './components/FooterBanner'


const App = () => {
  
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    // Set a timer to hide the overlay after 2 seconds
    const timer = setTimeout(() => {
      setShowOverlay(false);
    }, 4000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);



  return (
    <BrowserRouter>
    <ScrollToTop/>
    {showOverlay&&<ChristmasOverlay/>}
    <Header/>
      <Routes>
        <Route path="/" element={<LandingPage/>}/>
        <Route path="/blog/:id" element={<BlogDetail/>}/>
        {/* <Route path="/aboutus" element={<AboutUs/>}/> */}
        <Route path="/aboutus" element={<LandingPageAbout/>}/>
        <Route path="/contactus" element={<ContactForm/>}/>
        <Route path="/ourbrandproducts" element={<OurBrandProducts/>}/>
        <Route path="ourstore" element={<OurStore/>}/>
        <Route path="/career" element={<Career/>}/>
      </Routes>
      <img src={ChristmassImg} alt="Logo2" className='SideLogo2' />
      <a href="https://wa.me/2347059979306" ><img src={wp} alt="logo" className="WhatsAppIcon" /></a>
      <FooterBanner/>
      <Footer/>
  
    </BrowserRouter>
  )
}

export default App

// import React, { useState, useEffect } from 'react';
// import '../CSS/Carousel.css'; // Assuming you have a CSS file for styling
// import b1 from "../Images/b1 (1).png"
// import b2 from "../Images/b1 (2).png"
// import b3 from "../Images/b1 (3).png"
// import b4 from "../Images/b1 (4).png"
// import { useNavigate } from 'react-router-dom';

// const Carousel = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const navigate = useNavigate()



//   // Automatically move to next image every 3 seconds
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevState=>prevState+1)%4)
//     }, 1000); // Adjust interval as needed (3000ms = 3 seconds)

//     return () => clearInterval(interval); // Cleanup interval on unmount
//   }, []);

//   console.log(currentIndex)

//   return (
//     <div className="carousel-container">
//       <h1>OUR BRAND</h1>
//       <div className="carousel">
//        <img src={b1} alt="b1"/>
//        <img src={b2} alt="b1"/>
//        <img src={b3} alt="b1"/>
//        <img src={b4} alt="b1"/>
//       </div>
      
//       <p>

// Happy Man Bitters is a top-tier herbal liqueur made in Ghana by Charger Limited. It features premium ingredients such as Khaya Senegalensis, Mondia White, Nauclea Ethano, and water, providing a distinctive and refreshing flavor. With a 40% alcohol content, it guarantees both quality and a stimulating experience. Designed for adults 18 and over, Happy Man Bitters seamlessly combines tradition with excellence, delivering joy and satisfaction.</p>
//       <button onClick={()=>navigate("/ourbrandproducts")}>See More...</button>
//     </div>
//   );
// };

// export default Carousel;


import React, { useState, useEffect, useRef } from 'react';
import '../CSS/Carousel.css'; // Assuming you have a CSS file for styling
import b1 from "../Images/b1 (1).png";
import b2 from "../Images/b1 (2).png";
import b3 from "../Images/b1 (3).png";
import b4 from "../Images/b1 (4).png";
import { useNavigate } from 'react-router-dom';
import "animate.css"; // Import animate.css

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const observer = useRef(null);

  // Automatically move to next image every 1 second (adjust as needed)
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevState) => (prevState + 1) % 4);
    }, 1000); // Adjust interval as needed (1000ms = 1 second)

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // Observer to apply animations when elements come into view
  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const target = entry.target;

            // Apply animation classes to img, h1, and p elements
            if (target.tagName === 'IMG') {
              target.classList.add('animate__animated', 'animate__zoomIn', 'animate__slower');
            } else if (target.tagName === 'H1') {
              target.classList.add('animate__animated', 'animate__slideInLeft');
            } else if (target.tagName === 'P') {
              target.classList.add('animate__animated', 'animate__fadeInUp');
            }

            // Unobserve element after applying animation
            observer.current.unobserve(target);
          }
        });
      },
      { threshold: 0.3 } // Trigger when 30% of the element is visible
    );

    // Select and observe the img, h1, and p elements
    const imgElements = document.querySelectorAll('img');
    const h1Elements = document.querySelectorAll('h1');
    const pElements = document.querySelectorAll('p');

    imgElements.forEach((el) => observer.current.observe(el));
    h1Elements.forEach((el) => observer.current.observe(el));
    pElements.forEach((el) => observer.current.observe(el));

    // Cleanup observer on component unmount
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <div className="carousel-container">
      <h1>OUR BRAND</h1>
      <div className="carousel">
        <img src={b1} alt="b1" />
        <img src={b2} alt="b2" />
        <img src={b3} alt="b3" />
        <img src={b4} alt="b4" />
      </div>
      
      <p>
        Happy Man Bitters is a top-tier herbal liqueur made in Ghana by Charger Limited. It features premium ingredients such as Khaya Senegalensis, Mondia White, Nauclea Ethano, and water, providing a distinctive and refreshing flavor. With a 40% alcohol content, it guarantees both quality and a stimulating experience. Designed for adults 18 and over, Happy Man Bitters seamlessly combines tradition with excellence, delivering joy and satisfaction.
      </p>
      <button onClick={() => navigate("/ourbrandproducts")}>See More...</button>
    </div>
  );
};

export default Carousel;

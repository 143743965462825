


import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import blogs from '../data/blogs';
import '../CSS/Blogs.css';
import { motion } from 'framer-motion';
import 'animate.css'; // Import animate.css

const Blogs = () => {
  const observer = useRef(null);

  useEffect(() => {
    // Create an IntersectionObserver to handle animations when elements are in view
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const target = entry.target;

            // Apply animation classes for h1, h2, and p elements
            if (target.tagName === 'H1') {
              target.classList.add('animate__animated', 'animate__flip', 'animate__slower');
            } else if (target.tagName === 'H2') {
              target.classList.add('animate__animated', 'animate__slideInLeft', 'animate__slower');
            } else if (target.tagName === 'P') {
              target.classList.add('animate__animated', 'animate__fadeInUp','animate__slower' );
            }

            // Unobserve the element after animating to avoid re-triggering
            observer.current.unobserve(target);
          }
        });
      },
      { threshold: 0.3 } // Trigger animation when 30% of the element is visible
    );

    // Select all h1, h2, and p elements to observe
    const h1Elements = document.querySelectorAll('h1');
    const h2Elements = document.querySelectorAll('h2');
    const pElements = document.querySelectorAll('p');

    h1Elements.forEach((el) => observer.current.observe(el));
    h2Elements.forEach((el) => observer.current.observe(el));
    pElements.forEach((el) => observer.current.observe(el));

    // Cleanup observer on component unmount
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <div className='BlogsWrap'>
      <h1>WHAT HAPPY MAN WANTS YOU TO KNOW</h1>
      <div className="Blogs">
        {blogs.map(blog => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
    </div>
  );
};

const BlogCard = ({ blog }) => {
  return (
    <motion.div
      className="BlogCard"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      whileInView={{ opacity: 1, y: 0 }} // Trigger animation when in view
      viewport={{ once: false }} // Trigger animation every time it scrolls into view
    >
      <img src={blog.image} alt={blog.title} />
      <h2>{blog.title}</h2>
      <p>{blog.snippet}...</p>
      <Link to={`/blog/${blog.id}`} className="ReadMore">Read More</Link>
    </motion.div>
  );
};

export default Blogs;

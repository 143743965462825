import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../CSS/ContactUs.css';
import Logo from '../Images/happyman Logo design.jpg';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [feedback, setFeedback] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };



const handleSubmit = async (e) => {
  e.preventDefault();

  // Show loading state
  Swal.fire({
    title: 'Sending...',
    text: 'Please wait while we send your message.',
    allowOutsideClick: false,
    showConfirmButton: false,
    willOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await fetch('https://happymanbeverages.com/api/contact_form_endpoint.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    });

    const result = await response.json();

    if (result.success) {
      // Close the loading alert and show success
      Swal.fire({
        icon: 'success',
        title: 'Message Sent',
        text: 'Your message has been sent successfully!',
        confirmButtonText: 'OK'
      });
      // Optionally clear the form fields after submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    } else {
      // Close the loading alert and show error
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: result.error || 'Something went wrong. Please try again.',
        confirmButtonText: 'OK'
      });
    }
  } catch (error) {
    // Close the loading alert and show error
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Error sending the message. Please try again later.',
      confirmButtonText: 'OK'
    });
  }
};

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div className="ContactFormWrap">
      <div className="contact-form-container">
        <h2>Contact Us</h2>
        <img src={Logo} alt="Logo" />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">
          Submit
          </button>
        </form>
      
        <div className="footer-contact" style={{ textAlign: 'center', alignItems: 'center', color: 'green' }}>
          <p>Address: Teshie Nungua Estate, <br />
          Block 8, Plot 32-45 Ankwa Dobro,<br />
          Nsawam Adoagyiri, Eastern Region, Ghana.<br />
          P.O. BOX TN 155,
          </p>
          <p>Email: Office@happymanbeverages.com</p>
          <p>Phone: +234 705 997 9306</p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
